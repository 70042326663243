<template>
  <LoginDialog ref="loginDialog" hide-overlay :open-with="openWith()"/>
</template>

<script>
import LoginDialog from '@/app/modules/user/components/login-dialog'
import { mapGetters } from 'vuex'

export default {

  name: 'login',

  metaInfo () {
    return {
      title: 'Login',

      meta: [
        {
          property: 'og:title',
          content: 'Login',
          vmid: 'og:title'
        }
      ]
    }
  },

  components: {
    LoginDialog
  },

  computed: {
    ...mapGetters('user', ['user'])
  },

  methods: {
    openWith () {
      if (this.$route.query && this.$route.query.tab) {
        return this.$route.query.tab
      }
      return 'login'
    }
  },

  mounted () {
    if (this.user) {
      this.$router.push('/')
      return
    }
    this.$refs.loginDialog.open()
  }
}
</script>

<style lang="css" scoped>
</style>
